import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./styles/global.css";
import MyChelperHeader from "@/components/MyChelperHeader.vue";
import MyChelperNavLogo from "@/components/MyChelperNavLogo.vue";
import MyChelperFooter from "./components/MyChelperFooter.vue";

const app = createApp(App);

app.component("MyChelperHeader", MyChelperHeader);
app.component("MyChelperNavLogo", MyChelperNavLogo);
app.component("MyChelperFooter", MyChelperFooter);

app.use(store).use(router).mount("#app");
