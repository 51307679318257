<template>
  <div class="page">
    <!-- Navbar -->
    <nav class="navbar">
      <div class="navbar-brand">
        <MyChelperNavLogo />
      </div>
      <div class="nav-column">
        <button @click="goToLogin" class="orange-btn">Admin Login</button>
        <button @click="goToSignup" class="orange-btn">Admin Sign Up</button>
      </div>
    </nav>

    <!-- Hero Section -->
    <section class="hero">
      <div class="hero-content">
        <h1>Empowering Your Church Community</h1>
        <p>
          Connecting churches with their people through customized, easy-to-use
          apps.
        </p>
        <button @click="scheduleDemo" class="cta-btn">
          Schedule Your Online Demo Now
        </button>
      </div>
    </section>

    <!-- Why MyChelper? -->
    <section class="section">
      <h2>Why MyChelper?</h2>
      <p>MyChelper is <b>100% Free, 100% Customizable</b>.</p>
      <ul>
        <li>Increase engagement with your congregation.</li>
        <li>Send text messages, pictures, videos, and share URLs.</li>
        <li>Available on both iOS and Android.</li>
      </ul>
    </section>

    <!-- Subscription Tiers -->
    <section class="subscription-tiers">
      <h2>Choose Your Plan</h2>
      <div class="tiers">
        <!-- Free Tier -->
        <div class="tier">
          <h3>Shared App Experience</h3>
          <li>Set the church on the first run</li>
          <li>QR Scanner To Setup Your Church</li>
          <li>Community Tab access</li>
          <p class="price">Free</p>
          <button @click="scheduleDemo" class="common-btn">Learn More!</button>
        </div>

        <!-- $50/month Tier -->
        <div class="tier">
          <h2><span class="highlighted-brand-color">Best Value</span></h2>
          <h3>Custom App</h3>
          <li>You get your own App</li>
          <li>Choose <b>Your App Name</b></li>
          <li>Choose <b>Your App Icon</b></li>
          <li>My Community Tab Is Required</li>
          <li>Other Modules Are Optional</li>
          <p class="price">
            $50 / Month <br />
            or <br />
            $550 / Year
          </p>
          <button @click="scheduleDemo" class="common-btn">Learn More!</button>
        </div>

        <!-- $150/month Tier -->
        <div class="tier">
          <h3>Custom App+</h3>
          <li>You get your own App</li>
          <li>Choose <b>Your App Name</b></li>
          <li>Choose <b>Your App Icon</b></li>
          <li>You Have Full Contol</li>
          <li>All Modules Are Optional</li>
          <p class="price">
            $150 / Month <br />
            or <br />
            $1650 / Year
          </p>
          <button @click="scheduleDemo" class="common-btn">Learn More!</button>
        </div>
      </div>
    </section>

    <!-- Footer -->
    <MyChelperFooter />
  </div>
</template>

<script>
export default {
  methods: {
    goToLogin() {
      this.$router.push("/login");
    },
    goToSignup() {
      this.$router.push("/signup");
    },
    scheduleDemo() {
      window.open("https://calendly.com/jmorris-7", "_blank");
    },
  },
};
</script>
