<template>
  <footer class="footer">
    <p>
      &#169; 2020 - {{ currentYear }} <span>my</span
      ><span class="highlighted-brand-color">C</span
      ><span>helper - My Church/Community Helper</span>
    </p>
  </footer>
</template>

<script>
export default {
  name: "myChelperFooter",
  data() {
    return {
      currentYear: new Date().getFullYear(), // Dynamically set the current year
    };
  },
};
</script>
