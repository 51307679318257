<template>
  <div class="mychelper-header">
    <h1 class="logo">
      <span class="brand-color">my</span
      ><span class="highlighted-brand-color">C</span
      ><span class="brand-color">helper</span>
    </h1>
    <p class="tagline">My Church/Community Helper</p>
  </div>
</template>

<script>
export default {
  name: "myChelperHeader",
};
</script>

<style scoped>
.mychelper-header {
  text-align: center;
}

.logo {
  font-family: Arial, sans-serif;
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0;
}

.tagline {
  font-size: 1.25rem;
  color: #ff8500; /* Branding Orange */
  margin-top: 5px;
  font-family: Arial, sans-serif;
}
</style>
