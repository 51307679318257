<template>
  <p>
    Currently Customizing the App Home Screen is only avaiable through the
    MCAdmin App
  </p>
  <p>
    Download the
    <a
      href="https://apps.apple.com/us/app/mcadmin/id1480400552"
      target="_blank"
    >
      <span class="brand-color">M</span
      ><span class="highlighted-brand-color">C</span
      ><span class="brand-color">Admin App</span>
    </a>
  </p>
  <p>Soon we will bring this functionality here, stay tuned!</p>
</template>
